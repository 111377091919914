import React, { useState, useEffect } from "react";
import dodoGIF01 from "../../assets/images/dodoGIF01.gif"
import "./SectionOne.css"
import axios from 'axios';
import titlePic01 from "../../assets/images/titlePic01.png"
import titlePic02 from "../../assets/images/titlePic02.png"
import titlePic03 from "../../assets/images/titlePic03.png"
import titlePic04 from "../../assets/images/titlePic04.png"
import titlePic05 from "../../assets/images/titlePic05.png"

const SectionOne = () => {
    const colors = ["#9945FF", "#14F195"];
    const [backgroundColor, setBackgroundColor] = useState(colors[0]);
    const [tokenCa, setTokenCa] = useState("")

    // 获取ca
    const getTokenCa = async () => {
        try {
            const response = await axios.get("https://www.dododog.dog/api/getca")
            setTokenCa(response.data)
        } catch {

        }
    }

    useEffect(() => {
        getTokenCa()

        const interval = setInterval(() => {
            setBackgroundColor((prevColor) => {
                const newIndex = (colors.indexOf(prevColor) + 1) % colors.length;
                return colors[newIndex];
            });
        }, 500); // 

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="SectionOne" style={{ backgroundColor, transition: "background-color 0.5s ease" }}>
            <div className="richrich">
                {/* <h1 className="SectionOne-bounce-01 SectionOne-h1-01">DODODOG</h1> */}
                <img loading="lazy" className="SectionOne-bounce-01 SectionOne-img-title-01" src={titlePic01} />
            </div>

            <div className="container-fluid SectionOne-div-adjust">
                <div className="richrich row SectionOne-div-01">


                    <div className="richrich col-12 col-md-4 order-md-2">
                        <img loading="lazy" className="SectionOne-gif-01" src={dodoGIF01} />
                    </div>

                    <div className="richrich col-6 col-md-4 order-md-1">
                        {/* <h1 className="SectionOne-swing-01 SectionOne-h1-02">fourteen years old</h1> */}
                        <img loading="lazy" className="SectionOne-swing-01 SectionOne-img-title-02" src={titlePic02} />
                        {/* <h1 className="SectionOne-swing-02 SectionOne-h1-03">girl or old lady</h1> */}
                        <img loading="lazy" className="SectionOne-swing-02 SectionOne-img-title-03" src={titlePic03} />
                    </div>

                    <div className="richrich col-6 col-md-4 order-md-3">
                        {/* <h1 className="SectionOne-swing-02 SectionOne-h1-02">loves ball and is skilled</h1> */}
                        <img loading="lazy" className="SectionOne-swing-02 SectionOne-img-title-04" src={titlePic04} />
                        {/* <h1 className="SectionOne-swing-01 SectionOne-h1-03">awesome to meet you</h1> */}
                        <img loading="lazy" className="SectionOne-swing-01 SectionOne-img-title-05" src={titlePic05} />
                    </div>
                </div>
            </div>

            <div className="richrich SectionOne-div-adjust-h104">
                <h1 className="SectionOne-bounce-02 SectionOne-h1-04">{tokenCa[0] ? (tokenCa[0].token_ca) : ("")}</h1>
            </div>
        </div>
    )
}

export default SectionOne