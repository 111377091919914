import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import SectionOne from './components/SectionOne/SectionOne';
import Gradient from './components/Gradient/Gradient';

function App() {
  return (
    <div className="App">
      <SectionOne />
      <Gradient />
    </div>
  );
}

export default App;
