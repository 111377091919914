import React from "react";
import twitchLogo01 from "../../assets/images/twitchLogo01.png"
import liveShowPic01 from "../../assets/images/liveShowPic01.png"

const SectionTwo = () => {
    return (
        <div className="SectionTwo">
            <br /><br /><br />
            <div>
                <img loading="lazy" className="SectionTwo-img-liveshow richrich" src={liveShowPic01} />
            </div>
            <br /><br />

            <iframe className="SectionTwo-twitch" src="https://player.twitch.tv/?channel=dododogsol&parent=www.dododog.dog" allowFullScreen={true}></iframe>

            <br /><br />
            <div className="richrich">
                <h3>If you can't view it, click the icon to redirect</h3>
                <h3>Maybe DODODOG is sleeping, lmao!</h3>

                <br />
                <img loading="lazy" className="SectionTwo-twitch-logo richrich" onClick={() => window.open('https://www.twitch.tv/dododogsol', '_blank')} src={twitchLogo01} />

            </div>
            <br /><br /><br />
        </div>
    )
}

export default SectionTwo