import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Keyboard } from "swiper/modules";
import { format, subHours } from 'date-fns';
import { ClipLoader } from 'react-spinners';
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/keyboard";
import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import foodPic01 from "../../assets/images/foodpic01.png"
import foodBtnPic01 from "../../assets/images/foodBtnPic01.png"
import foodPic02 from "../../assets/images/foodpic02.png"
import foodPic03 from "../../assets/images/foodpic03.png"
import oopsPic01 from "../../assets/images/oopsPic01.png"
import refreshPic from "../../assets/images/refreshPic.png"
import interactionPic01 from "../../assets/images/interactionPic01.png"
import loginPic01 from "../../assets/images/loginPic01.png"
import signupPic01 from "../../assets/images/sginupPic01.png"
import userinfoPic01 from "../../assets/images/userinfoPic01.png"
import clothesPic01 from "../../assets/images/clothes01.png"
import clothesPic02 from "../../assets/images/clothes02.png"
import votedPic01 from "../../assets/images/votedPic01.png"
import foodBtnPic02 from "../../assets/images/foodBtnPic02.png"
import foodBtnPic03 from "../../assets/images/foodBtnPic03.png"
import clothesBtnPic01 from "../../assets/images/clothesBtnPic01.png"
import clothesBtnPic02 from "../../assets/images/clothesBtnPic02.png"
import alertPic01 from "../../assets/images/alertPic01.png"
import questionPic01 from "../../assets/images/question.png"
import top20RankPic01 from "../../assets/images/top20RankPic01.png"
import myInvitesPic01 from "../../assets/images/myInvitesPic01.png"

const SectionThree = () => {
    const [isUserLogin, setIsUserLogin] = useState(false)               // 用户是否已经登录
    const [isLoginDivShow, setIsLoginDivShow] = useState(false)         // 登陆注册div是否显示
    const [isLoginOrRegister, setIsLoginOrRegister] = useState(true)    // div是显示登录还是注册，true 为登录，false 为注册
    const [isRememberLogin, setIsRememberLogin] = useState(false)       // 是否选择记住登录
    const [isUserCenterShow, setIsUserCenterShow] = useState(false)     // 个人中心div是否显示
    const [username, setUsername] = useState("")                        // 登录时输入的用户名
    const [password, setPassword] = useState("")                        // 登录时输入的密码
    const [registerUsername, setRegisterUsername] = useState("")        // 注册时输入的用户名
    const [registerPassword, setRegisterPassword] = useState("")        // 注册时输入的密码
    const [registerConfirmPassword, setRegisterConfirmPassword] = useState("")
    const [solAddress, setSolAddress] = useState("")                    // 注册时输入的SOL地址
    const [acceptInviteCode, setAcceptInviteCode] = useState("")        // 注册时输入的其他用户的邀请码
    const [globalUsername, setGlobalUsername] = useState("")            // 全局用户名，用于后续用户进行的操作
    const [userinfo, setUserinfo] = useState([])                        // 用户信息
    const [voteinfo, setVoteinfo] = useState([])                        // 投票信息
    const [voteControl, setvoteControl] = useState([])                  // 投票控制信息
    const [userVoteState, setuserVoteState] = useState([])              // 包含用户投票状态信息
    const [isUserVoted, setIsUserVoted] = useState(true)                // 用户是否已经投票
    const [isMyAlertShow, setIsMyAlertShow] = useState(false)            // 自定义弹窗
    const [myAlertContent, setMyAlertContent] = useState("")            // 自定义弹窗内容
    const [isMyAlertOverlayShow, setIsMyAlertOverlayShow] = useState(false)     //阻止用户操作的蒙版
    const [topUsers, setTopUsers] = useState([])                                // 积分排名前20
    const [isTopUsersShow, setisTopUsersShow] = useState(false)
    const [myInvites, setMyInvites] = useState([])
    const [isMyInvitesShow, setisMyInvitesShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isSuperOverlayShow, setIsSuperOverlayShow] = useState(false)
    const [isLogoutDivShow, setIsLogoutDivShow] = useState(false)

    const [votePicBorder01, setVotePicBorder01] = useState(false)
    const [votePicBorder02, setVotePicBorder02] = useState(false)
    const [votePicBorder03, setVotePicBorder03] = useState(false)
    const [votePicBorder04, setVotePicBorder04] = useState(false)
    const [votePicBorder05, setVotePicBorder05] = useState(false)


    const ponitsAlert = "Invite one person for 1000, vote once for 100"
    const addressAlert = "For the safety of everyone's assets, we choose not to interact with users' wallets to reduce wallet risks. Please ensure the address you provide is correct. There will be related activities in the future"

    // 加载
    const loadingOpen = () => {
        setIsLoading(true)
        setIsSuperOverlayShow(true)
    }

    const loadingClose = () => {
        setIsLoading(false)
        setIsSuperOverlayShow(false)
    }

    // 自定义弹窗
    const myselfAlert = (alertContent) => {
        setMyAlertContent(alertContent)
        setIsMyAlertShow(true)
        setIsMyAlertOverlayShow(true)
    }

    // 关闭自定义弹窗
    const closeMyselfAlert = () => {
        setMyAlertContent("")
        setIsMyAlertShow(false)
        setIsMyAlertOverlayShow(false)
    }

    // 用户投票
    const userVote = async (voteId) => {
        if (!isUserLogin) {
            // setMyAlertContent("Please log in first.")
            // setIsMyAlertShow(true)

            myselfAlert("Please log in first")

            return
        }

        try {
            if (voteId === 1) {
                setVotePicBorder01(true)
            } else if (voteId === 2) {
                setVotePicBorder02(true)
            } else if (voteId === 3) {
                setVotePicBorder03(true)
            } else if (voteId === 4) {
                setVotePicBorder04(true)
            } else if (voteId === 5) {
                setVotePicBorder05(true)
            }

            loadingOpen()
            await axios.put("https://www.dododog.dog/api/vote", { globalUsername, voteId })  //    https://www.dododog.dog

            userVoteState[0].is_voted = 1

            getVoteinfo()
            setIsUserVoted(true)
            loadingClose()
        } catch {
            loadingClose()
            myselfAlert("Please try again later")
        }
    }

    // 退出登录
    const userLogout = () => {
        Cookies.remove("authToken");
        setIsUserLogin(false)
        setGlobalUsername("")
        setuserVoteState([])
        setIsUserCenterShow(false)
        setVotePicBorder01(false)
        setVotePicBorder02(false)
        setVotePicBorder03(false)
        setVotePicBorder04(false)
        setVotePicBorder05(false)
    }

    // 用户注册
    const userRegister = async () => {
        if (registerUsername === "" || registerPassword === "" || solAddress === "") {
            myselfAlert("Please enter content")

            return
        }

        // if (registerUsername.replace(/\s+/g, '').length < 5 || registerPassword.replace(/\s+/g, '').length < 5) {
        //     myselfAlert("Username and password must be at least 5 characters")

        //     return
        // }

        if (registerUsername.replace(/\s+/g, '').length < 5 || registerUsername.replace(/\s+/g, '').length > 20) {
            myselfAlert("The username must be between 5 and 20 characters");
            return;
        }

        // 验证密码长度和复杂性
        const password = registerPassword.replace(/\s+/g, '');
        const hasLetter = /[a-zA-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSymbol = /[!@#$%^&*.]/.test(password);

        if (password.length < 8 || password.length > 20 || !hasLetter || !hasNumber || !hasSymbol) {
            myselfAlert("Password must be between 8 and 20 characters and include at least one letter, one number, and one symbol(!@#$%^&*.)");
            return;
        }

        if (registerPassword !== registerConfirmPassword) {
            myselfAlert("The passwords do not match")

            return
        }

        if (solAddress.replace(/\s+/g, '').length !== 44 && solAddress.replace(/\s+/g, '').length !== 43) {
            myselfAlert("Please enter a valid sol address")

            return
        }



        try {
            loadingOpen()
            const response = await axios.post("https://www.dododog.dog/api/register", { registerUsername, registerPassword, solAddress, acceptInviteCode })

            if (response.status === 201) {
                loadingClose()

                myselfAlert("Registration successful")

                setRegisterUsername("")
                setRegisterPassword("")
                setRegisterConfirmPassword("")
                setSolAddress("")
                setAcceptInviteCode("")


            }
        } catch (error) {
            loadingClose()
            if (error.response) {
                const { status, data: { message } } = error.response

                if (status === 409) {
                    myselfAlert(message)
                } else if (status === 500) {
                    myselfAlert(message)
                } else if (status === 404) {
                    myselfAlert(message)
                } else {
                    myselfAlert("Please try again later")
                }
            } else {
                myselfAlert("Network error")
            }
        }
    }

    // 用户登录
    const userLogin = async () => {
        if (username === "" || password === "") {
            myselfAlert("Please enter content")

            return
        }

        if (username.replace(/\s+/g, '').length < 5 || username.replace(/\s+/g, '').length > 20 || password.replace(/\s+/g, '').length < 8 || password.replace(/\s+/g, '').length > 20) {
            myselfAlert("Please enter the correct username or password")

            return
        }

        try {
            loadingOpen()
            const response = await axios.post("https://www.dododog.dog/api/login", { username, password }, { withCredentials: true })

            if (response.status === 200) {
                loadingClose()

                setUsername("")
                setPassword("")
                setIsUserLogin(true)
                setGlobalUsername(response.data.username)
                getUserVoteState();
                setIsLoginDivShow(false)
                setIsMyAlertOverlayShow(false)

            }
        } catch (error) {
            loadingClose()
            if (error.response) {
                const { status, data: { message } } = error.response

                if (status === 401) {
                    myselfAlert(message)
                } else if (status === 404) {
                    myselfAlert(message)
                } else if (status === 500) {
                    myselfAlert(message)
                } else {
                    myselfAlert("Please try again later")
                }
            } else {
                myselfAlert("Network error")
            }
        }
    }

    // 获取用户信息
    const getUserinfo = async () => {
        setIsUserCenterShow(true)

        try {
            loadingOpen()
            const response = await axios.get(`https://www.dododog.dog/api/userinfo?globalUsername=${globalUsername}`)

            setUserinfo(response.data)

            loadingClose()
        } catch {
            loadingClose()
            myselfAlert("Please try again later")
        }
    }

    // 获取已邀请列表
    const getMyInvites = async () => {
        setisMyInvitesShow(true)

        try {
            loadingOpen()

            const response = await axios.get(`https://www.dododog.dog/api/getinvites?userId=${userinfo[0].id}`)

            setMyInvites(response.data)
            loadingClose()
        } catch {
            loadingClose()
            myselfAlert("Please try again later")
        }
    }




    // 获取用户投票状态
    const getUserVoteState = async () => {
        try {
            loadingOpen()
            const response = await axios.get(`https://www.dododog.dog/api/getvotestate?globalUsername=${globalUsername}`)
            setuserVoteState(response.data)

            if (response.data.length > 0) {
                if (response.data[0].vote_content_id === 1) {
                    setVotePicBorder01(true)
                } else if (response.data[0].vote_content_id === 2) {
                    setVotePicBorder02(true)
                } else if (response.data[0].vote_content_id === 3) {
                    setVotePicBorder03(true)
                } else if (response.data[0].vote_content_id === 4) {
                    setVotePicBorder04(true)
                } else if (response.data[0].vote_content_id === 5) {
                    setVotePicBorder05(true)
                } else {
                    setVotePicBorder01(false)
                    setVotePicBorder02(false)
                    setVotePicBorder03(false)
                    setVotePicBorder04(false)
                    setVotePicBorder05(false)
                }
            }
            loadingClose()
        } catch {
            loadingClose()
            myselfAlert("Please try again later")
        }
    }

    // 获取投票数据
    const getVoteinfo = async () => {
        try {
            loadingOpen()
            const response = await axios.get("https://www.dododog.dog/api/voteinfo")
            setVoteinfo(response.data)
            loadingClose()
        } catch (error) {
            loadingClose()
            myselfAlert("Please try again later")

            // alert("try agin later")
        }
    }

    // 积分前20
    const getTopUser = async () => {
        try {
            loadingOpen()
            const response = await axios.get("https://www.dododog.dog/api/topusers")
            setTopUsers(response.data)
            setisTopUsersShow(true)
            loadingClose()
        } catch (error) {
            loadingClose()
            myselfAlert("Please try again later")

            // alert("try agin later")
        }
    }



    // 是否开始投票
    const getIsVoteOpen = async () => {
        try {
            loadingOpen()
            const response = await axios.get("https://www.dododog.dog/api/isvoteopen")

            setvoteControl(response.data)

            loadingClose()
        } catch {
            loadingClose()
            myselfAlert("Please try again later")
        }
    }

    // 刷新按钮
    const refreshContent = () => {
        getVoteinfo()
        getIsVoteOpen()
        getUserVoteState()
    }

    useEffect(() => {

        const token = Cookies.get("authToken");

        getVoteinfo()
        getIsVoteOpen()


        if (token) {
            try {
                // 验证和解码 token
                const decodedToken = jwtDecode(token);

                // 检查 token 的有效期
                const currentTime = Date.now() / 1000;
                if (decodedToken.exp < currentTime) {
                    // token 已过期，移除 cookie
                    Cookies.remove("authToken");
                    setIsUserLogin(false);
                } else {
                    // token 有效，设置登录状态
                    setIsUserLogin(true);
                    setGlobalUsername(decodedToken.username)
                }
            } catch (error) {
                myselfAlert("Invalid userinfo")
                Cookies.remove("authToken");
                setIsUserLogin(false);
            }
        }
    }, []);

    useEffect(() => {
        if (globalUsername) {
            // 在 globalUsername 更新后再调用 getUserVoteState
            getUserVoteState();
        }
    }, [globalUsername]);

    return (
        <div className="SectionThree">
            <div>
                <br /><br /><br />
                <img loading="lazy" src={interactionPic01} className="SectionThree-img-interaction" />



                {/* <br />
                <button onClick={userLogout}>退出登录</button>
                <br />
                {isUserLogin && (
                    <h1 onClick={getUserinfo}>个人中心</h1>
                )} */}
                <br /><br />
                <h1>{voteControl[0] && voteControl[0].vote_open === 1 ? (
                    <div>
                        <h1>Voting is ongoing!</h1>
                        <h4>end time : {format(subHours(new Date(voteControl[0].vote_end_time), 8), 'yyyy-MM-dd HH:mm:ss', { timeZone: 'UTC' })} (UTC)</h4>
                    </div>
                ) : ("Voting has not started")}</h1>

            </div>
            <br /><br />

            <div>


                <Swiper
                    slidesPerView={3}
                    freeMode={true}
                    modules={[Scrollbar, Keyboard]}
                    scrollbar={{ draggable: true }}
                    keyboard={{ enabled: true }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1, // 屏幕宽度小于等于 768px 时显示 1 个 slide
                        },
                        768: {
                            slidesPerView: 3, // 屏幕宽度大于 768px 时显示 3 个 slide
                        },
                    }}
                >
                    <SwiperSlide className="richrich">
                        <div>
                            <img style={{ border: votePicBorder01 ? "10px #fceb3f solid" : "none" }} loading="lazy" className="SectionThree-swiper-img richrich" src={foodPic01} />
                            <br /><br />


                            {voteControl[0] && voteControl[0].vote_open === 1 ? (
                                userVoteState[0] && userVoteState[0].is_voted === 1 ? (
                                    <img loading="lazy" src={votedPic01} className="SectionThree-img-voted" />
                                ) : (
                                    <img loading="lazy" className="SectionThree-swiper-img-btn-01" onClick={() => userVote(1)} src={foodBtnPic01} />
                                )


                            ) : (
                                <img loading="lazy" className="SectionThree-swiper-img-btn-oops" src={oopsPic01} />
                            )}
                            <br /><br />
                            <h5>current votes : {voteinfo[0] ? (voteinfo[0].current_votes) : ("No data")}</h5>
                            <h5>total votes : {voteinfo[0] ? (voteinfo[0].total_votes) : ("No data")}</h5>

                            <br />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div>
                            <img style={{ border: votePicBorder02 ? "10px #fceb3f solid" : "none" }} loading="lazy" className="SectionThree-swiper-img" src={foodPic02} />
                            <br /><br />


                            {voteControl[0] && voteControl[0].vote_open === 1 ? (
                                userVoteState[0] && userVoteState[0].is_voted === 1 ? (
                                    <img loading="lazy" src={votedPic01} className="SectionThree-img-voted" />
                                ) : (
                                    <img loading="lazy" className="SectionThree-swiper-img-btn" onClick={() => userVote(2)} src={foodBtnPic02} />
                                )


                            ) : (
                                <img loading="lazy" className="SectionThree-swiper-img-btn-oops" src={oopsPic01} />
                            )}
                            <br /><br />
                            <h5>current votes : {voteinfo[1] ? (voteinfo[1].current_votes) : ("No data")}</h5>
                            <h5>total votes : {voteinfo[1] ? (voteinfo[1].total_votes) : ("No data")}</h5>

                            <br />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div>
                            <img style={{ border: votePicBorder03 ? "10px #fceb3f solid" : "none" }} loading="lazy" className="SectionThree-swiper-img" src={foodPic03} />

                            <br /><br />


                            {voteControl[0] && voteControl[0].vote_open === 1 ? (
                                userVoteState[0] && userVoteState[0].is_voted === 1 ? (
                                    <img loading="lazy" src={votedPic01} className="SectionThree-img-voted" />
                                ) : (
                                    <img loading="lazy" className="SectionThree-swiper-img-btn" onClick={() => userVote(3)} src={foodBtnPic03} />
                                )


                            ) : (
                                <img loading="lazy" className="SectionThree-swiper-img-btn-oops" src={oopsPic01} />
                            )}
                            <br /><br />
                            <h5>current votes : {voteinfo[2] ? (voteinfo[2].current_votes) : ("No data")}</h5>
                            <h5>total votes : {voteinfo[2] ? (voteinfo[2].total_votes) : ("No data")}</h5>

                            <br />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div>
                            <img style={{ border: votePicBorder04 ? "10px #fceb3f solid" : "none" }} loading="lazy" className="SectionThree-swiper-img" src={clothesPic01} />

                            <br /><br />


                            {voteControl[0] && voteControl[0].vote_open === 1 ? (
                                userVoteState[0] && userVoteState[0].is_voted === 1 ? (
                                    <img loading="lazy" src={votedPic01} className="SectionThree-img-voted" />
                                ) : (
                                    <img loading="lazy" className="SectionThree-swiper-img-btn" onClick={() => userVote(4)} src={clothesBtnPic01} />
                                )


                            ) : (
                                <img loading="lazy" className="SectionThree-swiper-img-btn-oops" src={oopsPic01} />
                            )}
                            <br /><br />
                            <h5>current votes : {voteinfo[3] ? (voteinfo[3].current_votes) : ("No data")}</h5>
                            <h5>total votes : {voteinfo[3] ? (voteinfo[3].total_votes) : ("No data")}</h5>

                            <br />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div>
                            <img style={{ border: votePicBorder05 ? "10px #fceb3f solid" : "none" }} loading="lazy" className="SectionThree-swiper-img" src={clothesPic02} />

                            <br /><br />


                            {voteControl[0] && voteControl[0].vote_open === 1 ? (
                                userVoteState[0] && userVoteState[0].is_voted === 1 ? (
                                    <img loading="lazy" src={votedPic01} className="SectionThree-img-voted" />
                                ) : (
                                    <img loading="lazy" className="SectionThree-swiper-img-btn" onClick={() => userVote(5)} src={clothesBtnPic02} />
                                )


                            ) : (
                                <img loading="lazy" className="SectionThree-swiper-img-btn-oops" src={oopsPic01} />
                            )}
                            <br /><br />
                            <h5>current votes : {voteinfo[4] ? (voteinfo[4].current_votes) : ("No data")}</h5>
                            <h5>total votes : {voteinfo[4] ? (voteinfo[4].total_votes) : ("No data")}</h5>

                            <br />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <br />

            <img loading="lazy" src={refreshPic} className="SectionThree-img-refresh-pic" onClick={refreshContent} />
            <br /><br /><br /><br />
            {isLoginDivShow && (
                <div className="SectionThree-div-login">
                    {isLoginOrRegister ? (
                        <div className="richrich">
                            <br /><br />
                            <img loading="lazy" className="SectionThree-img-login-pic" src={loginPic01} />
                            <br /><br /><br />
                            <h4>username : <input className="SectionThree-input-loginorregister" onChange={(e) => setUsername(e.target.value)} /></h4>
                            <br /><br />
                            <h4>password : <input type="password" className="SectionThree-input-loginorregister" onChange={(e) => setPassword(e.target.value)} /></h4>
                            <br /><br />
                            <h2 style={{ cursor: "pointer" }} onClick={userLogin}>Log in</h2>
                            <br />
                            <h2 style={{ cursor: "pointer" }} onClick={() => {
                                setUsername("")
                                setPassword("")
                                setIsLoginOrRegister(false)
                            }}>Sign up now</h2>
                            <br />
                            <h2 style={{ cursor: "pointer" }} onClick={() => {
                                setIsLoginDivShow(false)
                                setUsername("")
                                setPassword("")
                                setRegisterUsername("")
                                setRegisterPassword("")
                                setRegisterConfirmPassword("")
                                setSolAddress("")
                                setAcceptInviteCode("")
                                setIsMyAlertOverlayShow(false)
                            }}>Close</h2>
                            <br />
                        </div>
                    ) : (
                        <div >
                            <br /><br />
                            <img loading="lazy" className="SectionThree-img-sign-pic" src={signupPic01} />
                            <br /><br /><br />
                            <div className="richrich" style={{ textAlign: "right", display: "inline-block" }}>
                                <h4 className="SectionThree-h4-register">username : <input className="SectionThree-input-loginorregister" value={registerUsername} onChange={(e) => setRegisterUsername(e.target.value)} /></h4>
                                <br />
                                <h4 className="SectionThree-h4-register">password : <input type="password" className="SectionThree-input-loginorregister" value={registerPassword} onChange={(e) => setRegisterPassword(e.target.value)} /></h4>
                                <br />
                                <h4 className="SectionThree-h4-register">confirm password : <input type="password" className="SectionThree-input-loginorregister" value={registerConfirmPassword} onChange={(e) => setRegisterConfirmPassword(e.target.value)} /></h4>
                                <br />
                                <h4 className="SectionThree-h4-register">sol address <img loading="lazy" onClick={() => myselfAlert(addressAlert)} style={{ width: "20px", cursor: "pointer" }} src={questionPic01} /> : <input className="SectionThree-input-loginorregister" value={solAddress} onChange={(e) => setSolAddress(e.target.value)} /></h4>
                                <br />
                                <h4 className="SectionThree-h4-register">invitation code : <input placeholder="<optional>" className="SectionThree-input-loginorregister" value={acceptInviteCode} onChange={(e) => setAcceptInviteCode(e.target.value)} /></h4>
                            </div>
                            <br /><br />
                            <h2 style={{ cursor: "pointer" }} onClick={userRegister}>Sign up</h2>
                            <br />
                            <h2 style={{ cursor: "pointer" }} onClick={() => {
                                setRegisterUsername("")
                                setRegisterPassword("")
                                setRegisterConfirmPassword("")
                                setSolAddress("")
                                setAcceptInviteCode("")
                                setIsLoginOrRegister(true)
                            }}>Log in now</h2>
                            <br />
                            <h2 style={{ cursor: "pointer" }} onClick={() => {
                                setIsLoginDivShow(false)
                                setUsername("")
                                setPassword("")
                                setRegisterUsername("")
                                setRegisterPassword("")
                                setRegisterConfirmPassword("")
                                setSolAddress("")
                                setAcceptInviteCode("")
                                setIsMyAlertOverlayShow(false)
                            }}>Close</h2>
                            <br />
                        </div>
                    )}
                </div>
            )}

            {isUserCenterShow && (
                <div className="SectionThree-div-usercenter">
                    <br /><br />
                    <img loading="lazy" src={userinfoPic01} className="SectionThree-img-userinfo" />
                    <br /><br />
                    <div style={{ textAlign: "left" }}>
                        <h3><span style={{ color: "black" }}>username :</span> {userinfo[0] ? (userinfo[0].username) : ("No data")}</h3>

                        <h3><span style={{ color: "black" }}>points <img loading="lazy" onClick={() => myselfAlert(ponitsAlert)} style={{ width: "20px", cursor: "pointer" }} src={questionPic01} /> :</span> {userinfo[0] ? (userinfo[0].points) : ("No data")}</h3>


                        <h3><span style={{ color: "black" }}>sol address <img loading="lazy" onClick={() => myselfAlert(addressAlert)} style={{ width: "20px", cursor: "pointer" }} src={questionPic01} /> :</span> {userinfo[0] ? (userinfo[0].address) : ("No data")}</h3>

                        <h3><span style={{ color: "black" }}>my invitation code :</span> {userinfo[0] ? (userinfo[0].id) : ("No data")}</h3>

                        <h3><span style={{ color: "black" }}>invites :</span> {userinfo[0] ? (userinfo[0].invite_num) : ("No data")}</h3>

                        <h3><span style={{ color: "black" }}>votes :</span> {userinfo[0] ? (userinfo[0].vote_num) : ("No data")}</h3>
                    </div>
                    <br />
                    <h2 style={{ cursor: "pointer" }} onClick={getTopUser} >Points ranking</h2>
                    <br />
                    <h2 style={{ cursor: "pointer" }} onClick={getMyInvites} >My invites</h2>
                    <br />
                    <h2 style={{ cursor: "pointer" }} onClick={() => {
                        setIsLogoutDivShow(true)
                        setIsSuperOverlayShow(true)
                    }}>Log out</h2>
                    <br />
                    <h2 style={{ cursor: "pointer" }} onClick={() => setIsUserCenterShow(false)}>Close</h2>
                    <br />
                </div>
            )}

            <div className="SectionThree-div-fixed-user">
                {isUserLogin ? (
                    <h1 onClick={getUserinfo}>{globalUsername !== "" ? (globalUsername) : ("No data")}</h1>
                ) : (
                    <h1 onClick={() => {
                        setIsLoginDivShow(true)
                        setIsMyAlertOverlayShow(true)
                    }}>Log in</h1>
                )}
            </div>

            {isMyAlertShow && (
                <div className="SectionThree-div-my-alert">
                    <img loading="lazy" className="SectionThree-img-alert-oops" src={alertPic01} />
                    <br /><br /><br />
                    <h1>"{myAlertContent}"</h1>
                    <br /><br />
                    <h1 style={{ cursor: "pointer" }} onClick={closeMyselfAlert}>Close</h1>
                </div>
            )}

            {isLogoutDivShow && (
                <div className="SectionThree-div-my-alert-logout">
                    <img loading="lazy" className="SectionThree-img-alert-oops" src={alertPic01} />
                    <br /><br /><br />
                    <h1>"Log out?"</h1>
                    <br /><br />
                    <div className="row">
                        <div className="col"><h1 style={{ cursor: "pointer" }} onClick={() => {
                            userLogout()
                            setIsLogoutDivShow(false)
                            setIsSuperOverlayShow(false)
                        }}>Yes</h1></div>
                        <div className="col"><h1 style={{ cursor: "pointer" }} onClick={() => {
                            setIsLogoutDivShow(false)
                            setIsSuperOverlayShow(false)
                        }}>No</h1></div>
                    </div>
                </div>
            )}



            {isMyAlertOverlayShow && (
                <div className="overlay" id="overlay"></div>
            )}

            {isSuperOverlayShow && (
                <div className="super-overly" id="superoverlay"></div>
            )}

            {isTopUsersShow && (
                <div className="SectionThree-div-top-20">
                    <br />
                    <img loading="lazy" className="SectionThree-img-top20" src={top20RankPic01} />
                    <br /><br />
                    <div className="row">
                        <div className="col"><h1>username</h1></div>
                        <div className="col"><h1>points</h1></div>
                    </div>
                    <br />
                    {topUsers.map((top) => (
                        <div className="row table-vote-content" key={top.username} >
                            <div className="col"><h5>{top.username}</h5></div>
                            <div className="col"><h5>{top.points}</h5></div>
                        </div>
                    ))}
                    <br />
                    <h2 style={{ cursor: "pointer" }} onClick={() => setisTopUsersShow(false)}>Close</h2>
                </div>
            )}

            {isMyInvitesShow && (
                <div className="SectionThree-div-top-20">
                    <br />
                    <img loading="lazy" className="SectionThree-img-top20" src={myInvitesPic01} />
                    <br /><br />
                    <div>
                        *Show only the first 20
                    </div>
                    <br />
                    {/* {myInvites.map((mi) => (
                        <div className="row" key={mi.username} >
                            <div className="col"><h5>{mi.username}</h5></div>
                            <div className="col"><h5>{mi.username}</h5></div>
                        </div>
                    ))} */}



                    {myInvites.length === 0 ? (
                        <div>
                            <h1>You haven't invited anyone yet, go invite some people!</h1>
                        </div>
                    ) : (
                        myInvites.slice(0, Math.ceil(myInvites.length / 2)).map((mi, index) => (
                            <div className="row" key={index}>
                                <div className="col"><h5>{mi.username}</h5></div>
                                <div className="col">
                                    {myInvites[index + Math.ceil(myInvites.length / 2)] && (
                                        <h5>{myInvites[index + Math.ceil(myInvites.length / 2)].username}</h5>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                    <br />
                    <h2 style={{ cursor: "pointer" }} onClick={() => setisMyInvitesShow(false)}>Close</h2>
                </div>
            )}


            {isLoading && (
                <div className="SectionThree-div-loading">
                    <div className="spinner-border"></div>
                </div>
            )}

        </div>
    )
}

export default SectionThree