import React from "react";
import storyPic01 from "../../assets/images/storyPic01.png"
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Keyboard } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/keyboard";
import oldpic01 from "../../assets/images/oldpic01.png"
import happypic01 from "../../assets/images/happypic01.png"
import oldpic02 from "../../assets/images/oldpic02.png"
import happypic02 from "../../assets/images/happypic02.png"
import oldpic03 from "../../assets/images/oldpic03.png"
import happypic03 from "../../assets/images/happypic03.png"
import byepic01 from "../../assets/images/byepic01.png"
import xlogo from "../../assets/images/XLOGO.png"

const SectionFour = () => {
    return (
        <div className="SectionFour">
            <br /><br /><br />
            <div>
                <img loading="lazy" src={storyPic01} className="SectionFour-img-storypic" />
            </div>
            <br /><br />
            <Swiper
                slidesPerView={1}
                freeMode={true}
                modules={[Scrollbar, Keyboard]} // 使用 Scrollbar 模块
                scrollbar={{ draggable: true }} // 启用滚动条并允许拖动
                keyboard={{ enabled: true }}
            >
                <SwiperSlide className="richrich">
                    <div className="row">
                        <div className="col-md richrich SectionFour-div-swiper-pic">
                            <img loading="lazy" className="storyPic richrich" src={oldpic01} />
                        </div>
                        <div className="col-md swipe-text richrich">
                            <h1 className="richrich SectionFour-h1-swiper-text">"Even though I'm getting up there in years and slowing down a bit..."</h1>
                        </div>

                    </div>
                    <br /><br /><br />
                </SwiperSlide>
                <SwiperSlide className="richrich">
                    <div className="row">
                        <div className="col-md SectionFour-div-swiper-pic">
                            <img loading="lazy" className="storyPic richrich" src={happypic01} />
                        </div>
                        <div className="col-md swipe-text richrich">
                            <h1 className="richrich SectionFour-h1-swiper-text">"But I can still cross you up on the basketball court, lmao!"</h1>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="richrich">
                    <div className="row">
                        <div className="col-md SectionFour-div-swiper-pic">
                            <img loading="lazy" className="storyPic richrich" src={oldpic02} />
                        </div>
                        <div className="col-md swipe-text">
                            <h1 className="SectionFour-h1-swiper-text">"Even though I'm getting up there in years and might seem a bit dull now..."</h1>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="richrich">
                    <div className="row">
                        <div className="col-md SectionFour-div-swiper-pic">
                            <img loading="lazy" className="storyPic richrich" src={happypic02} />
                        </div>
                        <div className="col-md swipe-text">
                            <h1 className="SectionFour-h1-swiper-text">"But I can still show off my booty anytime!"</h1>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="richrich">
                    <div className="row">
                        <div className="col-md SectionFour-div-swiper-pic">
                            <img loading="lazy" className="storyPic richrich" src={oldpic03} />
                        </div>
                        <div className="col-md swipe-text">
                            <h1 className="SectionFour-h1-swiper-text">"Even though I spend most of the day sleeping...<br />(Can't sleep without my snacks)"</h1>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="richrich">
                    <div className="row">
                        <div className="col-md SectionFour-div-swiper-pic">
                            <img loading="lazy" className="storyPic richrich" src={happypic03} />
                        </div>
                        <div className="col-md swipe-text">
                            <h1 className="SectionFour-h1-swiper-text">"But I still make time to hang with my family <br />(Even if it's my least favorite little brother)"</h1>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="richrich">
                    <div className="row">
                        <div className="col-md SectionFour-div-swiper-pic">
                            <img loading="lazy" className="storyPic richrich" src={byepic01} />
                        </div>
                        <div className="col-md swipe-text">
                            <h1 className="SectionFour-h1-swiper-text">"I'll be sharing more of my stories with you down the road. Catch you later!"</h1>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
            <br /><br /><br /><br />

            <div className="SectionFour-div-x richrich">
                <span style={{ fontSize: "25px", cursor: "pointer", fontWeight: "900" }} onClick={() => window.open('https://x.com/dododogsol', '_blank')}><img loading="lazy" className="SectionFour-img-x-logo" src={xlogo} /> @dododogsol</span>
            </div>
        </div>
    )
}

export default SectionFour