import React from "react";
import "./Gradient.css"
import SectionTwo from '../SectionTwo/SectionTwo';
import SectionThree from "../SectionThree/SectionThree";
import SectionFour from "../SectionFour/SectionFour";

const Gradient = () => {
    return (
        <div className="Gradient">
            <SectionTwo />
            <SectionThree />
            <SectionFour />
        </div>
    )
}

export default Gradient